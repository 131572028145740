<template>
  <v-dialog v-model="show_modal_var" persistent max-width="800">
    <v-card>
      <div class="headerIcon">
        <v-icon class="secondary--text" @click="show_modal_var = false">
          mdi-close
        </v-icon>
      </div>

      <!-- form -->
      <v-card-text>
        <v-row class="mx-sm-6">
          <v-col cols="12">
            <span
              class="text-h6 secondary--text"
              v-text="'Selección de administrador de contrato'"
            />
          </v-col>
          <v-row class="mx-sm-1 my-2">
            <!-- Correo Electrónico -->
            <v-col cols="12" sm="7">
              <v-text-field
                label="Correo Electrónico *"
                outlined
                v-model="email_var"
                :clearable="!status_verify"
                :readonly="bloquearCampoEmail"
              />
            </v-col>

            <!-- boton que verifica el correo electrónico -->
            <v-col cols="12" sm="5">
              <v-btn
                :clearable="false"
                :disabled="$v.email_var.$invalid"
                color="btnGreen"
                :block="$vuetify.breakpoint.xs"
                class="text-capitalize mt-sm-3 ml-sm-4 px-8"
                dark
                @click="VerifyEmpleadoFtn"
              >
                Verificar
              </v-btn>
            </v-col>

            <!-- informacion de la persona -->
            <v-expand-transition>
              <v-col cols="12" v-show="status_verify">
                <div class="d-flex flex-column text-center text-sm-start">
                  <span v-text="'Nombre:'" />
                  <span class="text-label" v-text="NombreCompletoFtn()" />
                </div>
                <div class="d-flex flex-column text-center text-sm-start my-6">
                  <span v-text="'Correo Electrónico:'" />
                  <span
                    class="text-label"
                    v-text="info_persona_var.email"
                  />
                </div>
              </v-col>
            </v-expand-transition>
          </v-row>
        </v-row>

        <!-- actions -->
        <v-row cols="12" class="d-flex flex-column flex-sm-row mx-sm-4 mb-6">
          <!-- boton que cierra el modal -->
          <v-btn
            class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4"
            color="secondary"
            outlined
            @click="show_modal_var = false"
          >
            Cancelar
          </v-btn>

          <!-- boton que agregar al administrador al proceso -->
          <v-btn
            :disabled="!info_persona_var?.email"
            class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4"
            color="secondary"
            @click="AddAdminFtn()"
          >
            Seleccionar
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
    
<script>
// importacion de librerias
import { required, helpers } from "vuelidate/lib/validators";
import { mapActions } from "vuex";

/**
 * @description Funcion que verifica si el numero de documento es valido
 * @param {String} doc_param
 * @returns {Boolean}
 */

export default {
  name: "ModalAddAdminComponent",

  validations: {
    email_var: {
      required,
    },
  },

  data: () => ({
    // variables
    show_modal_var: false,
    email_var: "",
    info_persona_var: {},
    status_verify: false,
    bloquearCampoEmail: false,
  }),

  computed: {
    // errores computados de vuelidate
    emailAdminError() {
      const errors = [];
      if (!this.$v.numero_documento_var.$dirty) return errors;
      !this.$v.numero_documento_var.required &&
        errors.push("El número de documento es requerido");
      return errors;
    },
  },

  methods: {
    ...mapActions("ProcCompReservaStore", ["FetchListAdminAc"]),

    NombreCompletoFtn() {
      return `${this.info_persona_var.primer_nombre || ""} ${
        this.info_persona_var.segundo_nombre || ""
      } ${this.info_persona_var.primer_apellido || ""} ${
        this.info_persona_var.segundo_apellido || ""
      } ${this.info_persona_var.apellido_casada || ""}`;
    },

    // <-- ----------------- FUNCIONES ASINCRONAS ---------------- -->

    async VerifyEmpleadoFtn() {

      let params = {
          email: this.email_var,
          id_solicitud_compra: Number(this.$route.params.id_solicitud),
        };

        const response = await this.services.SolicitudCompra.verificarEmpleado(params);
        if (response.status === 200) {
          this.info_persona_var = response.data;
          this.status_verify = true;
          this.bloquearCampoEmail = true;
        } else {
          this.temporalAlert({
            show: true,
            message: response.data,
            type: "error",
          });
        }

    },

    /**
     * @description Funcion que lanza una petición al backend para guardar los datos y agregar un administrador de contrato al proceso (API)
     * @async
     */
    async AddAdminFtn() {

      const { status, data } =
        await this.services.SolicitudCompra.agregarAdministradorContrato(
          this.$route.params.id_solicitud,
          { email: this.email_var }
        );
      if (status == 201) {
        this.temporalAlert({
          message: data.message,
          show: true,
          type: "success",
        });
        this.show_modal_var = false;
        this.FetchListAdminAc({
          id_solicitud: this.$route.params.id_solicitud,
          paginate: { per_page: 10, page: 1 },
        });
      }

    },
  },

  watch: {
    show_modal_var(val) {
      if (!val) {
        this.email_var = "";
        this.info_persona_var = {};
        this.status_verify = false;
        this.bloquearCampoEmail = false;
      }
    },
  },
};
</script>

<style scoped>
/* clase para texto de informacion */
.v-application .text-label {
  font-size: 1.1rem !important;
  letter-spacing: 0.0125em !important;
  color: #000000 !important;
}
</style>
<!-- Componente Modal para agregar administradores de contrato al proceso -->