<template>
  <section>
    <v-row>
      <!-- header -->
      <v-col cols="12" class="py-0">
        <div
          class="mb-6 d-flex justify-center flex-column justify-sm-start flex-sm-row"
        >
          <!-- boton que muestra el modal de agregar especifcacion -->
          <v-btn
            class="button-extra text-no-style mt-4 mt-sm-0 mx-8 mx-sm-4"
            color="secondary"
            @click="$refs.REF_MODAL_ADD_CONST.show_modal_var = true"
          >
            Agregar
          </v-btn>
        </div>
      </v-col>

      <!-- table -->
      <v-col cols="12">
        <data-table-component
          :actions="ACTIONS_CONST"
          :headers="HEADERS_CONST"
          :items="list_especificaciones_st"
          class="px-1"
          mobile_breakpoint="650"
          table_title="Listado de especificaciones"
          :show_actions="true"
          :total_registros="pag_solicitudes_st.total_rows"
          @paginar="PaginateFtn"
          v-models:pagina="pag_solicitudes_st.page"
          v-models:select="pag_solicitudes_st.per_page"
          @downloadFileEvent="DownloadFileFtn"
          @deleteFileEvent="OpenModalDeleteFtn"
        >
          <!-- slot de fecha carga -->
          <template v-slot:[`item.created_at`]="{ item }">
            {{ moment(item.created_at).format("DD/MM/YYYY") }}
          </template>
        </data-table-component>
      </v-col>
    </v-row>

    <!-- modal de agregar -->
    <modal-add-especf-component ref="REF_MODAL_ADD_CONST" />

    <!-- modal de confirmacion -->
    <confirmation-dialog-component
      :show="show_modal_delete_var"
      title="¿Deseas eliminar la especificación seleccionada?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Aceptar"
      @close="show_modal_delete_var = false"
      @confirm="DeleteFileFtn"
    />
  </section>
</template>
  
<script>
// importacion de componentes locales
import ModalAddEspecfComponent from "../ModalAddEspecfComponent.vue";

// importacion de componentes globales
import DataTableComponent from "../../../../../components/DataTableComponent.vue";
import ConfirmationDialogComponent from "../../../../../components/ConfirmationDialogComponent.vue";

// importacion de librerias
import { mapState, mapActions } from "vuex";

export default {
  name: "TabEspecificacionesComponent",

  components: {
    ModalAddEspecfComponent,
    DataTableComponent,
    ConfirmationDialogComponent,
  },

  data: () => ({
    // constantes
    HEADERS_CONST: [
      {
        text: "Documento",
        value: "nombre",
        align: "center",
      },
      {
        text: "Fecha de carga",
        value: "created_at",
        align: "center",
      },
      {
        text: "Acciones",
        value: "actions",
        align: "center",
        sortable: false,
      },
    ],

    /*
     * //WARNING
     * Este array es parte de una props para el componente DataTableComponent,
     * por lo que no se debe modificar su estructura, si desea agregar condiciones especiales,
     * favor de crear una funcion del tipo init para cargar las acciones, o simplemente
     * trabajar con slots
     */
    ACTIONS_CONST: [
      {
        tooltip: {
          text: "Descargar",
          color: "blueGrayMinsal",
        },
        icon: {
          color: "secondary",
          name: "mdi-download",
        },
        eventName: "downloadFileEvent",
      },
      {
        tooltip: {
          text: "Eliminar",
          color: "blueGrayMinsal",
        },
        icon: {
          color: "secondary",
          name: "mdi-close",
        },
        eventName: "deleteFileEvent",
      },
    ],

    // variables
    show_modal_delete_var: false,
    id_file_select_var: null,
  }),

  computed: {
    ...mapState("ProcCompReservaStore", [
      "list_especificaciones_st",
      "pag_solicitudes_st",
    ]),
  },

  methods: {
    ...mapActions("ProcCompReservaStore", ["FetchListEspecAc"]),

    PaginateFtn({ cantidad_por_pagina, pagina }) {
      this.FetchListEspecAc({
        id_solicitud: this.$route.params.id_solicitud,
        paginate: { per_page: cantidad_por_pagina, page: pagina },
      });
    },

    OpenModalDeleteFtn(item_param) {
      this.id_file_select_var = item_param.id;
      this.show_modal_delete_var = true;
    },

    async DownloadFileFtn(item_param) {

      const { data, status, headers } =
        await this.services.SolicitudCompra.downloadDocEspecificacion(
          this.$route.params.id_solicitud,
          item_param.id
        );
      if (status) {
        const blob = new Blob([data], {
          type: headers["content-type"],
        });

        const file = new File([blob], item_param.nombre, {
          type: headers["content-type"],
        });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(file);
        link.download = file.name;
        link.click();
      }

    },

    async DeleteFileFtn() {

      const { data, status } =
        await this.services.SolicitudCompra.deleteDocEspecificacion(
          this.$route.params.id_solicitud,
          this.id_file_select_var
        );

      if (status == 200) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: data.message,
        });
        this.show_modal_delete_var = false;
        this.id_file_select_var = null;
        this.FetchListEspecAc({
          id_solicitud: this.$route.params.id_solicitud,
          paginate: { per_page: 10, page: 1 },
        });
      }

    },
  },

  created() {
    this.FetchListEspecAc({
      id_solicitud: this.$route.params.id_solicitud,
      paginate: { per_page: 10, page: 1 },
    });
  },
};
</script>
<!-- Componente Tab con informacion de especificaciones del proceso de compra -->