<template>
  <section>
    <v-row no-gutters>
      <!-- header -->
      <!-- <v-row class="px-sm-3">
        <v-col cols="12" sm="6">
          <v-autocomplete
            label="Cifrado presupuestario"
            outlined
            v-model="id_cifrado_var"
            :items="ctl_cifrados_var"
            item-text="cifrado_presupuestario"
            item-value="id"
          />
        </v-col>
        <v-col cols="12" sm="4">
          <money-text-field-component
            label="Monto a solicitar"
            v-model="monto_var"
          />
        </v-col>
        <v-col cols="12" sm="2">
          <v-btn
            class="button-extra text-no-style mt-3"
            outlined
            @click="AddFinanmtFtn"
          >
            Agregar
          </v-btn>
        </v-col>
      </v-row> -->

      <!-- table -->
      <v-col cols="12">
        <data-table-component
          :actions="ACTIONS_CONST"
          :headers="HEADERS_CONST"
          :items="list_financiamientos_st"
          class="px-1"
          mobile_breakpoint="650"
          table_title="Listado de financiamientos"
          :show_actions="true"
          :total_registros="pag_financiamientos_st.total_rows"
          @paginar="PaginateFtn"
          v-models:pagina="pag_financiamientos_st.page"
          v-models:select="pag_financiamientos_st.per_page"
          @deleteItemEvent="OpenModalDeleteFtn"
        >
          <!-- slot de monto -->
          <template v-slot:[`item.monto`]="{ item }">
            {{
              Intl.NumberFormat("en-US", {
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(item.monto)
            }}
          </template>
        </data-table-component>
      </v-col>
    </v-row>

    <!-- modal de confirmacion -->
    <confirmation-dialog-component
      :show="show_modal_delete_var"
      title="¿Deseas eliminar el financiamiento seleccionado?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Aceptar"
      @close="show_modal_delete_var = false"
      @confirm="DeleteFinanmtFtn"
    />
  </section>
</template>
      
    <script>
// importacion de componentes locales

// importacion de componentes globales
import DataTableComponent from "../../../../../components/DataTableComponent.vue";
import ConfirmationDialogComponent from "../../../../../components/ConfirmationDialogComponent.vue";
import MoneyTextFieldComponent from "../../../../../components/MoneyTextFieldComponent.vue";

// importacion de librerias
import { mapState, mapActions } from "vuex";

export default {
  name: "TabFinanciamientoComponent",

  components: {
    DataTableComponent,
    ConfirmationDialogComponent,
    MoneyTextFieldComponent,
  },

  data: () => ({
    // constantes
    HEADERS_CONST: [
      {
        text: "Unidad",
        value: "FinanciamientoUnidad.Unidad.nombre",
        align: "center",
      },
      {
        text: "Código presupuestario",
        value: "FinanciamientoUnidad.Fondo.cifrado_presupuestario",
        align: "center",
      },
      {
        text: "Monto ($)",
        value: "monto",
        align: "center",
      },
      {
        text: "Acciones",
        value: "actions",
        align: "center",
        sortable: false,
      },
    ],

    /*
     * //WARNING
     * Este array es parte de una props para el componente DataTableComponent,
     * por lo que no se debe modificar su estructura, si desea agregar condiciones especiales,
     * favor de crear una funcion del tipo init para cargar las acciones, o simplemente
     * trabajar con slots
     */
    ACTIONS_CONST: [
      {
        tooltip: {
          text: "Eliminar",
          color: "blueGrayMinsal",
        },
        icon: {
          color: "secondary",
          name: "mdi-close",
        },
        eventName: "deleteItemEvent",
      },
    ],

    // variables
    id_cifrado_var: null,
    monto_var: null,
    ctl_cifrados_var: [],
    show_modal_delete_var: false,
    item_select_var: null,
  }),

  computed: {
    ...mapState("ProcCompReservaStore", [
      "list_financiamientos_st",
      "pag_financiamientos_st",
      "unidad_solicitud_st",
    ]),
  },

  methods: {
    ...mapActions("ProcCompReservaStore", ["FetchListFinanAc"]),

    PaginateFtn({ cantidad_por_pagina, pagina }) {
      this.FetchListFinanAc({
        id_proceso: this.$route.params.id_proceso,
        paginate: { per_page: cantidad_por_pagina, page: pagina },
      });
    },

    OpenModalDeleteFtn(item_param) {
      this.item_select_var = item_param;
      this.show_modal_delete_var = true;
    },

    async DeleteFinanmtFtn() {

      const { data, status } =
        await this.services.ProcesoReserva.deleteFinanciamiento(
          this.item_select_var.id_solicitud_necesidad,
          this.item_select_var.id
        );

      if (status == 200) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: data.message,
        });
        this.show_modal_delete_var = false;
        this.item_select_var = null;
        this.FetchListFinanAc({
          id_proceso: this.$route.params.id_proceso,
          paginate: { per_page: 10, page: 1 },
        });
      }

    },

    /**
     * @description Funcion para obtener los datos de catalogo de cifrados (API)
     * @async
     */
    // async FetchCtlCifradosFtn() {
    //

    //   const { data, status } = await this.services.Cifrados.getCifrados({
    //     id_unidad: this.unidad_solicitud_st.id,
    //     fondos: true,
    //   });
    //   if (status == 200) {
    //     this.ctl_cifrados_var = data;
    //   }

    //
    // },

    // async AddFinanmtFtn() {
    //
    //   const { data, status } =
    //     await this.services.Financiamientos.addFinanciamiento({
    //       id_proceso: this.$route.params.id_proceso,
    //       id_cifrado: this.id_cifrado_var,
    //       monto: this.monto_var,
    //     });

    //   if (status == 200) {
    //     this.FetchListFinanAc({
    //       id_proceso: this.$route.params.id_proceso,
    //       paginate: { per_page: 10, page: 1 },
    //     });
    //     this.id_cifrado_var = null;
    //     this.monto_var = null;
    //   }

    //
    // },
  },

  created() {
    this.FetchListFinanAc({
      id_proceso: this.$route.params.id_proceso,
      paginate: { per_page: 10, page: 1 },
    });
    // this.FetchCtlCifradosFtn();
  },
};
</script>
    <!-- Componente Tab con informacion de especificaciones del proceso de compra -->