<template>
  <section>
    <v-row>
      <!-- header -->
      <v-col cols="12" class="py-0">
        <div
          class="mb-6 d-flex justify-center flex-column justify-sm-start flex-sm-row"
        >
          <!-- boton que muestra el modal de agregar administradores de contrato -->
          <v-btn
            class="button-extra text-no-style mt-4 mt-sm-0 mx-8 mx-sm-4"
            color="secondary"
            @click="$refs.REF_MODAL_ADD_CONST.show_modal_var = true"
          >
            Agregar administrador
          </v-btn>
        </div>
      </v-col>

      <!-- table -->
      <v-col cols="12">
        <data-table-component
          :actions="ACTIONS_CONST"
          :headers="HEADERS_CONST"
          :items="list_administradores_st"
          class="px-1"
          mobile_breakpoint="875"
          table_title="Listado de administradores de contrato"
          :show_actions="true"
          :total_registros="pag_administradores_st.total_rows"
          @paginar="PaginateFtn"
          v-models:pagina="pag_administradores_st.page"
          v-models:select="pag_administradores_st.per_page"
          @deleteItemEvent="OpenModalDeleteFtn"
        >
          <!-- slot de nombre empleado -->
          <template v-slot:[`item.nombre_empleado`]="{ item }">
            {{ item.Empleado.Persona.primer_nombre || "" }}
            {{ item.Empleado.Persona.segundo_nombre || "" }}
          </template>
          <!-- slot de nombre empleado -->
          <template v-slot:[`item.apellido_empleado`]="{ item }">
            {{ item.Empleado.Persona.primer_apellido || "" }}
            {{ item.Empleado.Persona.segundo_apellido || "" }}
          </template>
          <template v-slot:[`item.numero_documento`]="{ item }">
            {{ item.Empleado.Persona?.DocumentoPersonas?.numero_documento }}
          </template>
          <template v-slot:[`item.email`]="{ item }">
            {{ item.Empleado.Usuario?.email }}
          </template>
        </data-table-component>
      </v-col>
    </v-row>

    <!-- modal de agregar -->
    <modal-add-admin-component-vue ref="REF_MODAL_ADD_CONST" />

    <!-- modal de confirmacion -->
    <confirmation-dialog-component
      :show="show_modal_delete_var"
      title="¿Deseas eliminar el administrador seleccionado?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Aceptar"
      @close="show_modal_delete_var = false"
      @confirm="DeleteAdminFtn"
    />
  </section>
</template>
    
<script>
// importacion de componentes locales
import ModalAddAdminComponentVue from "../ModalAddAdminComponent.vue";

// importacion de componentes globales
import DataTableComponent from "../../../../../components/DataTableComponent.vue";
import ConfirmationDialogComponent from "../../../../../components/ConfirmationDialogComponent.vue";

// importacion de librerias
import { mapState, mapActions } from "vuex";

export default {
  name: "TabAdministradoresComponent",

  components: {
    ModalAddAdminComponentVue,
    DataTableComponent,
    ConfirmationDialogComponent,
  },

  data: () => ({
    // constantes
    HEADERS_CONST: [
      {
        text: "Correlativo",
        value: "correlativo",
        align: "center",
        sortable: false,
      },
      {
        text: "Nombres",
        value: "nombre_empleado",
        align: "center",
        sortable: false,
      },
      {
        text: "Apellidos",
        value: "apellido_empleado",
        align: "center",
        sortable: false,
      },
      {
        text: "DUI",
        value: "numero_documento",
        align: "center",
        sortable: false,
      },
      {
        text: "Correo Electrónico",
        value: "email",
        align: "center",
        sortable: false,
      },
      {
        text: "Acciones",
        value: "actions",
        align: "center",
        sortable: false,
      },
    ],

    /*
     * //WARNING
     * Este array es parte de una props para el componente DataTableComponent,
     * por lo que no se debe modificar su estructura, si desea agregar condiciones especiales,
     * favor de crear una funcion del tipo init para cargar las acciones, o simplemente
     * trabajar con slots
     */
    ACTIONS_CONST: [
      {
        tooltip: {
          text: "Eliminar",
          color: "blueGrayMinsal",
        },
        icon: {
          color: "secondary",
          name: "mdi-delete",
        },
        eventName: "deleteItemEvent",
      },
    ],

    // variables
    show_modal_delete_var: false,
    id_item_select_var: null,
  }),

  computed: {
    ...mapState("ProcCompReservaStore", [
      "list_administradores_st",
      "pag_administradores_st",
    ]),
  },

  methods: {
    ...mapActions("ProcCompReservaStore", ["FetchListAdminAc"]),

    PaginateFtn({ cantidad_por_pagina, pagina }) {
      this.FetchListAdminAc({
        id_solicitud: this.$route.params.id_solicitud,
        paginate: { per_page: cantidad_por_pagina, page: pagina },
      });
    },

    OpenModalDeleteFtn(item_param) {
      this.item_select_var = item_param;
      this.show_modal_delete_var = true;
    },

    async DeleteAdminFtn() {

      const { data, status } =
        await this.services.SolicitudCompra.deleteAdministradorContrato(
          this.$route.params.id_solicitud,
          { id_empleado_solicitud: this.item_select_var.id }
        );

      if (status == 200) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: data.message,
        });
        this.show_modal_delete_var = false;
        this.item_select_var = null;
        this.FetchListAdminAc({
          id_solicitud: this.$route.params.id_solicitud,
          paginate: { per_page: 10, page: 1 },
        });
      }

    },
  },

  created() {
    this.FetchListAdminAc({
      id_solicitud: this.$route.params.id_solicitud,
      paginate: { per_page: 10, page: 1 },
    });
  },
};
</script>
<!-- Componente Tab con informacion de administradores de contrato del proceso de compra -->