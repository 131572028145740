<template>
  <section>
    <!-- header -->
    <v-tabs
      align-with-title
      background-color="bgMinsal"
      class="v-step-0"
      mobile-breakpoint="0"
      transition
      v-model="selected_tab_var"
    >
      <div class="d-flex" v-for="tab in tabs_var" :key="tab.id">
        <v-tab class="text-no-style mx-0" v-if="tab.show">
          <v-icon>{{ tab.icon }}</v-icon>
          {{ tab.name }}
        </v-tab>
      </div>
    </v-tabs>

    <!-- body -->
    <v-tabs-items v-model="selected_tab_var" touchless>
      <div v-for="tab in tabs_var" :key="tab.id">
        <v-tab-item v-if="tab.show" class="py-12">
          <component :is="tab.component" />
        </v-tab-item>
      </div>
    </v-tabs-items>
  </section>
</template>
    
<script>
// importaciones de componentes locales
import TabInsumosComponent from "./Tabs/TabInsumosComponent.vue";
import TabEspecificacionesComponent from "./Tabs/TabEspecificacionesComponent.vue";
import TabPreciosComponent from "./Tabs/TabPreciosComponent.vue";
import TabAdministradoresComponent from "./Tabs/TabAdministradoresComponent.vue";
import TabFinanciamientosComponent from "./Tabs/TabFinanciamientosComponent.vue";

export default {
  name: "ContainerSTabsComponent",

  data: () => ({
    // variables
    tabs_var: [],
    selected_tab_var: 0,
  }),

  methods: {
    // <-- ----------------- FUNCIONES REGULARES ---------------- -->

    /**
     *
     * @description Inicializa las tabs del componente
     */
    initTabs() {

      this.tabs_var = [
        {
          id: 0,
          name: "Insumos",
          icon: "mdi-archive",
          component: TabInsumosComponent,
          show: true,
        },
        {
          id: 1,
          name: "Específicaciones",
          icon: "mdi-clipboard-list",
          component: TabEspecificacionesComponent,
          show: true,
        },
        {
          id: 2,
          name: "Precios de mercado",
          icon: "mdi-cash",
          component: TabPreciosComponent,
          show: true,
        },
        {
          id: 3,
          name: "Administradores del contrato",
          icon: "mdi-badge-account",
          component: TabAdministradoresComponent,
          show: true,
        },
        {
          id: 4,
          name: "Financiamientos",
          icon: "mdi-currency-usd",
          component: TabFinanciamientosComponent,
          show: true,
        },
      ];
    },
  },

  created() {
    this.initTabs();
  },
};
</script>
<!-- Componente contenedor de tabs de creacion de proceso -->