<template>
  <section class="mx-0 mx-sm-3 mx-md-6">
    <!-- title -->
    <header class="d-flex flex-column">
      <div class="my-6 text-center text-sm-left">
        <span
          class="text-h5 secondary--text"
          v-text="'Solicitud de compra con reserva'"
        />
      </div>
      <div class="mb-6 text-center text-sm-left">
        <span class="text-h6 primary--text">
          Unidad de {{ unidad_solicitud_st?.nombre }}
        </span>
      </div>
    </header>

    <!-- container -->
    <container-s-tabs-component />

    <!-- footer -->
    <footer
      tile
      class="py-4 mt-8 d-flex flex-column flex-sm-row"
      color="bgMinsal"
    >
      <!-- boton que redirecciona a la vista anterior -->
      <v-btn
        class="button-extra text-capitalize mt-4 mt-sm-0"
        color="secondary"
        outlined
        @click="$router.back()"
      >
        Volver
      </v-btn>
    </footer>
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
// importaciones locales
import ContainerSTabsComponent from "./components/ContainerSTabsComponent.vue";

export default {
  name: "mainSolicitudView",

  components: {
    ContainerSTabsComponent,
  },

  computed: {
    ...mapState("ProcCompReservaStore", ["unidad_solicitud_st"]),
  },

  methods: {
    ...mapActions("ProcCompReservaStore", ["FetchDetailSolicAc"]),
  },

  created() {
    this.FetchDetailSolicAc(this.$route.params.id_solicitud);
  },

  beforeDestroy() {
    this.$store.dispatch("ProcCompReservaStore/ResetAllListAc");
  },
};
</script>
<!-- Vista de edición/mostrar de solicitud de proceso con reserva -->