<template>
  <section>
    <v-row>
      <!-- header -->
      <v-col cols="12" class="py-0">
        <div
          class="mb-6 d-flex justify-center flex-column justify-sm-start flex-sm-row"
        >
          <!-- boton que muestra el modal de agregar insumos -->
          <v-btn
            class="button-extra text-no-style mt-4 mt-sm-0 mx-8 mx-sm-4"
            color="secondary"
            @click="
              $router.push({ name: 'proceso-compra-reserva-agregar-insumo' })
            "
          >
            Agregar insumo
          </v-btn>
        </div>
      </v-col>

      <!-- table -->
      <v-col cols="12">
        <data-table-component
          :actions="ACTIONS_CONST"
          :headers="HEADERS_CONST"
          :inhabilitar_paginacion="true"
          :items="list_insumos_st"
          :show_actions="true"
          :tiene_paginacion="false"
          class="px-1"
          mobile_breakpoint="650"
          table_title="Listado de insumos"
          @editElementEvent="ToViewFtn"
          @deleteFileEvent="OpenModalDeleteFtn"
        >
          <!-- slot de precio unitario -->
          <template v-slot:[`item.precio_unitario`]="{ item }">
            {{
              Intl.NumberFormat("en-US", {
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(item.precio_unitario)
            }}
          </template>
        </data-table-component>
      </v-col>
    </v-row>

    <!-- modal de confirmacion -->
    <confirmation-dialog-component
      :show="show_modal_delete_var"
      title="¿Deseas eliminar el insumo seleccionado?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Aceptar"
      @close="show_modal_delete_var = false"
      @confirm="DeleteInsumoFtn"
    />
  </section>
</template>
    
  <script>
// importacion de componentes locales

// importacion de componentes globales
import DataTableComponent from "../../../../../components/DataTableComponent.vue";
import ConfirmationDialogComponent from "../../../../../components/ConfirmationDialogComponent.vue";

// importacion de librerias
import { mapActions, mapState } from "vuex";

export default {
  name: "TabInsumosComponent",

  components: {
    DataTableComponent,
    ConfirmationDialogComponent,
  },

  data: () => ({
    // constantes
    HEADERS_CONST: [
      {
        text: "No",
        value: "correlativo",
        align: "center",
      },
      {
        text: "OBS",
        value: "obs",
        align: "center",
      },
      {
        text: "Especificación",
        value: "observacion",
        align: "center",
      },
      {
        text: "Unidad de medida",
        value: "unidad_medida",
        align: "center",
      },
      {
        text: "Cantidad",
        value: "solicitud_compra_detalle_obs[0].cantidad",
        align: "center",
      },
      {
        text: "Precio unitario ($)",
        value: "precio_unitario",
        align: "center",
      },
      {
        text: "Acciones",
        value: "actions",
        align: "center",
        sortable: false,
      },
    ],

    /*
     * //WARNING
     * Este array es parte de una props para el componente DataTableComponent,
     * por lo que no se debe modificar su estructura, si desea agregar condiciones especiales,
     * favor de crear una funcion del tipo init para cargar las acciones, o simplemente
     * trabajar con slots
     */
    ACTIONS_CONST: [
      {
        tooltip: {
          text: "Editar",
          color: "blueGrayMinsal",
        },
        icon: {
          color: "secondary",
          name: "mdi-pencil",
        },
        eventName: "editElementEvent",
      },
      {
        tooltip: {
          text: "Eliminar",
          color: "blueGrayMinsal",
        },
        icon: {
          color: "secondary",
          name: "mdi-close",
        },
        eventName: "deleteFileEvent",
      },
    ],

    // variables
    show_modal_delete_var: false,
    id_insumo_select_var: null,
  }),

  computed: {
    ...mapState("ProcCompReservaStore", ["list_insumos_st"]),
  },

  methods: {
    ...mapActions("ProcCompReservaStore", ["FetchDetailSolicAc"]),

    ToViewFtn(item_param) {
      this.$router.push({
        name: "proceso-compra-reserva-editar-insumo",
        params: { id_insumo: item_param.id },
      });
    },

    OpenModalDeleteFtn(item_param) {
      this.show_modal_delete_var = true;
      this.id_insumo_select_var = item_param.id;
    },

    async DeleteInsumoFtn() {

      const { data, status } =
        await this.services.SolicitudCompra.deleteInsumoProcesoCompra(
          this.$route.params.id_solicitud,
          this.id_insumo_select_var
        );

      if (status) {
        this.temporalAlert({
          message: data.message,
          show: true,
          type: "success",
        });
      }

      this.id_insumo_select_var = null;
      this.show_modal_delete_var = false;

      this.FetchDetailSolicAc(this.$route.params.id_solicitud);
    },
  },
};
</script>
  <!-- Componente Tab con informacion de especificaciones del proceso de compra -->