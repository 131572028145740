<template>
  <v-dialog v-model="show_modal_var" persistent max-width="800">
    <v-card>
      <div class="headerIcon">
        <v-icon class="secondary--text" @click="show_modal_var = false">
          mdi-close
        </v-icon>
      </div>

      <!-- form -->
      <v-card-text>
        <v-row class="mx-sm-6">
          <v-col cols="12">
            <span
              class="text-h6 secondary--text"
              v-text="'Agregar documento de especificación'"
            />
          </v-col>
          <!-- nombre del archivo -->
          <v-col cols="12" sm="12" class="pb-1">
            <v-text-field
              :error-messages="nombreArchivoErrorCmp"
              @blur="$v.nombre_archivo_var.$touch()"
              @input="$v.nombre_archivo_var.$touch()"
              label="Nombre del archivo *"
              outlined
              v-model="nombre_archivo_var"
            />
          </v-col>

          <!-- archivo -->
          <v-col cols="12" sm="12" class="pt-1">
            <v-file-input
              :error-messages="archivoErrorCmp"
              @blur="$v.archivo_var.$touch()"
              @input="$v.archivo_var.$touch()"
              accept="application/pdf"
              clearable
              label="Archivo *"
              outlined
              placeholder="Adjuntar archivo"
              prepend-icon=""
              prepend-inner-icon="mdi-paperclip"
              v-model="archivo_var"
            />
          </v-col>
        </v-row>

        <!-- actions -->
        <v-row cols="12" class="d-flex flex-column flex-sm-row mx-sm-4 mb-6">
          <!-- boton que cierra el modal -->
          <v-btn
            class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4"
            color="secondary"
            outlined
            @click="show_modal_var = false"
          >
            Cancelar
          </v-btn>

          <!-- boton que agrega el documento al proceso -->
          <v-btn
            class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4"
            color="secondary"
            @click="AddDocumentFtn"
          >
            Agregar
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
  
<script>
// importacion de librerias
import { required, helpers } from "vuelidate/lib/validators";
import { mapActions } from "vuex";

// regex para validar campos de texto
const txtField = helpers.regex("txtField", /^[A-Za-zñÑáéíóúÁÉÍÓÚüÜ0-9 -]*$/);

/**
 * @description validacion de nombre de archivo
 * @param {String} value_param
 * @returns {Boolean}
 */
function fileNameValid(value_param) {
  if (!value_param) {
    return true;
  }
  let extension_scp_var = /(\.pdf)$/i;
  let file_scp_var = value_param;
  return extension_scp_var.test(file_scp_var.name);
}

/**
 * @description validacion de tamaño de archivo
 * @param {String} value_param
 * @returns {Boolean}
 */
function fileSizeValid(value_param) {
  if (!value_param) {
    return true;
  }
  let file_scp_var = value_param;
  return file_scp_var.size < 512000;
}

export default {
  name: "ModalAddEspecfComponent",

  validations: {
    nombre_archivo_var: {
      required,
      txtField,
    },
    archivo_var: {
      required,
      fileNameValid,
      fileSizeValid,
    },
  },

  data: () => ({
    // variables
    show_modal_var: false,
    nombre_archivo_var: "",
    archivo_var: null,
  }),

  computed: {
    // errores de validacion computados de vuelidate
    nombreArchivoErrorCmp() {
      const errors = [];
      if (!this.$v.nombre_archivo_var.$dirty) return errors;
      !this.$v.nombre_archivo_var.required &&
        errors.push("El nombre del archivo es requerido");
      !this.$v.nombre_archivo_var.txtField &&
        errors.push("El nombre del archivo no es válido");
      return errors;
    },

    archivoErrorCmp() {
      const errors = [];
      if (!this.$v.archivo_var.$dirty) return errors;
      !this.$v.archivo_var.required && errors.push("El archivo es requerido");
      !this.$v.archivo_var.fileNameValid &&
        errors.push("El archivo debe ser un pdf");
      !this.$v.archivo_var.fileSizeValid &&
        errors.push("El archivo debe pesar menos de 512Kb");
      return errors;
    },
  },

  methods: {
    ...mapActions("ProcCompReservaStore", ["FetchListEspecAc"]),

    // <-- ----------------- FUNCIONES ASINCRONAS ---------------- -->

    /**
     * @description Funcion que lanza una petición al backend para guardar los datos y agregar una especificacion al proceso (API)
     * @async
     */
    async AddDocumentFtn() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      } else {
        let formData = new FormData();
        formData.append("nombre_documento", this.nombre_archivo_var);
        formData.append("documento", this.archivo_var);

        const { status, data } =
          await this.services.SolicitudCompra.addDocEspecificacion(
            this.$route.params.id_solicitud,
            formData
          );
        if (status === 200) {
          this.temporalAlert({
            show: true,
            type: "success",
            message: data.message,
          });

          this.show_modal_var = false;
          this.FetchListEspecAc({
            id_solicitud: this.$route.params.id_solicitud,
            paginate: { per_page: 10, page: 1 },
          });
        }

      }
    },
  },

  watch: {
    // watch que verifica si el modal esta desactivado para resetear los campos
    show_modal_var(val) {
      if (!val) {
        this.$v.$reset();
        this.nombre_archivo_var = "";
        this.archivo_var = null;
      }
    },
  },
};
</script>
<!-- Componente Modal para agregar especificaciones al proceso -->