<template>
  <section>
    <v-row>
      <!-- header -->
      <v-col cols="12" class="py-0">
        <div
          class="mb-6 d-flex justify-center flex-column justify-sm-start flex-sm-row"
        >
          <!-- boton que muestra el modal de agregar precios del mercado -->
          <v-btn
            class="button-extra text-no-style mt-4 mt-sm-0 mx-8 mx-sm-4"
            color="secondary"
            @click="$refs.REF_MODAL_ADD_CONST.show_modal_var = true"
          >
            Agregar
          </v-btn>
        </div>
      </v-col>

      <!-- table -->
      <v-col cols="12">
        <data-table-component
          class="px-1"
          :actions="ACTIONS_CONST"
          :headers="HEADERS_CONST"
          :items="list_precios_st"
          mobile_breakpoint="650"
          table_title="Listado de precios de mercado"
          :show_actions="true"
          :total_registros="pag_precios_st.total_rows"
          @paginar="PaginateFtn"
          v-models:pagina="pag_precios_st.page"
          v-models:select="pag_precios_st.per_page"
          @downloadFileEvent="DownloadFileFtn"
          @deleteFileEvent="OpenModalDeleteFtn"
        >
          <!-- slot de fecha carga -->
          <template v-slot:[`item.fecha_carga`]="{ item }">
            {{ moment(item.fecha_carga).format("DD/MM/YYYY") }}
          </template>
        </data-table-component>
      </v-col>
    </v-row>

    <!-- modal de agregar -->
    <modal-add-especificacion-component ref="REF_MODAL_ADD_CONST" />

    <!-- modal de confirmacion -->
    <confirmation-dialog-component
      :show="show_modal_delete_var"
      title="¿Deseas eliminar el documento seleccionado?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Aceptar"
      @close="show_modal_delete_var = false"
      @confirm="DeletePrecioFtn"
    />
  </section>
</template>
    
<script>
// importacion de componentes locales
import ModalAddEspecificacionComponent from "../ModalAddPreciosComponent.vue";

// importacion de componentes globales
import DataTableComponent from "../../../../../components/DataTableComponent.vue";
import ConfirmationDialogComponent from "../../../../../components/ConfirmationDialogComponent.vue";

// importacion de librerias
import { mapState, mapActions } from "vuex";

export default {
  name: "TabPreciosComponent",

  components: {
    ModalAddEspecificacionComponent,
    DataTableComponent,
    ConfirmationDialogComponent,
  },

  data: () => ({
    // constantes
    HEADERS_CONST: [
      {
        text: "Documento",
        value: "nombre_documento",
        align: "center",
      },
      {
        text: "Fecha de carga",
        value: "fecha_carga",
        align: "center",
      },
      {
        text: "Acciones",
        value: "actions",
        align: "center",
        sortable: false,
      },
    ],

    /*
     * //WARNING
     * Este array es parte de una props para el componente DataTableComponent,
     * por lo que no se debe modificar su estructura, si desea agregar condiciones especiales,
     * favor de crear una funcion del tipo init para cargar las acciones, o simplemente
     * trabajar con slots
     */
    ACTIONS_CONST: [
      {
        tooltip: {
          text: "Descargar",
          color: "blueGrayMinsal",
        },
        icon: {
          color: "secondary",
          name: "mdi-download",
        },
        eventName: "downloadFileEvent",
      },
      {
        tooltip: {
          text: "Eliminar",
          color: "blueGrayMinsal",
        },
        icon: {
          color: "secondary",
          name: "mdi-close",
        },
        eventName: "deleteFileEvent",
      },
    ],

    // variables
    show_modal_delete_var: false,
    id_file_select_var: null,
  }),

  computed: {
    ...mapState("ProcCompReservaStore", ["list_precios_st", "pag_precios_st"]),
  },

  methods: {
    ...mapActions("ProcCompReservaStore", ["FetchListPreciosAc"]),

    PaginateFtn({ cantidad_por_pagina, pagina }) {
      this.FetchListPreciosAc({
        id_solicitud: this.$route.params.id_solicitud,
        paginate: { per_page: cantidad_por_pagina, page: pagina },
      });
    },

    OpenModalDeleteFtn(item_param) {
      this.id_file_select_var = item_param.id;
      this.show_modal_delete_var = true;
    },

    async DeletePrecioFtn() {

      const { status } =
        await this.services.SolicitudCompra.deleteSolicitudCotizacionDetalle(
          this.id_file_select_var
        );

      if (status == 204) {
        this.temporalAlert({
          show: true,
          message: "Detalle de cotización eliminado con éxito",
          type: "success",
        });
        this.FetchListPreciosAc({
          id_solicitud: this.$route.params.id_solicitud,
          paginate: { per_page: 10, page: 1 },
        });
        this.show_modal_delete_var = false;
      }

    },

    async DownloadFileFtn(item_param) {

      const { data, status } =
        await this.services.SolicitudCompra.getUrlDocumento({
          documento: item_param.documento_ruta,
        });
      if (status == 200) {
        const blob = new Blob([data], {
          type: "application/pdf",
        });

        const file = new File([blob], item_param.nombre_documento, {
          type: "application/pdf",
        });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(file);
        link.download = file.name;
        link.click();
      }

    },
  },

  created() {
    this.FetchListPreciosAc({
      id_solicitud: this.$route.params.id_solicitud,
      paginate: { per_page: 10, page: 1 },
    });
  },
};
</script>
<!-- Componente Tab con informacion de precios de mercado del proceso de compra -->